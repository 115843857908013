<template>
  <div class="incapacity">
    <div class="title">
      <span>失能老年人健康评估表和健康指导内容（试行）</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr border-bottom">
          <div class="td border-right td-width">
            <div class="td-left border-right">
              <span>姓名</span>
            </div>
            <div class="flex1">
              <span></span>
            </div>
          </div>
          <div class="td border-right td-width1">
            <span>性别</span>
          </div>
          <div class="td flex1 border-right">
            <span></span>
          </div>
          <div class="td border-right td-width1">
            <span>年龄</span>
          </div>
          <div class="td flex1 border-right">
            <span></span>
          </div>
          <div class="td border-right td-width1">
            <span>住址</span>
          </div>
          <div class="td flex2">
            <span></span>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width">
            <div class="td-left border-right">
              <span>联系人</span>
            </div>
            <div class="flex1">
              <span></span>
            </div>
          </div>
          <div class="td border-right td-width1">
            <span>电话</span>
          </div>
          <div class="td flex1 border-right">
            <span></span>
          </div>
          <div class="td border-right td-width1">
            <span>健康档案号</span>
          </div>
          <div class="td flex1 border-right">
            <span></span>
          </div>
          <div class="td border-right td-width1">
            <span>签约医生</span>
          </div>
          <div class="td flex2">
            <span></span>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>能力等级*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">能力完好</el-radio>
              <el-radio label="1">轻度失能</el-radio>
              <el-radio label="2">中度失能</el-radio>
              <el-radio label="3">重度失能</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>压疮风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>跌倒风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>烫伤风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>窒息/误吸风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>坠床风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>脑卒中风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>吞咽功能评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>管路滑脱风险评估*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>当前疾病情况*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr border-bottom">
          <div class="td border-right td-width center">
            <span>当前用药情况*</span>
          </div>
          <div class="td flex1 center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">无风险</el-radio>
              <el-radio label="1">低危</el-radio>
              <el-radio label="2">中危</el-radio>
              <el-radio label="3">高危</el-radio>
            </el-radio-group>
          </div>
        </div>
        <div class="tr">
          <div class="td border-right td-width center">
            <span>综合评估</span>
          </div>
          <div class="td flex1 center2">
              <span>经上述内容评估，结果为(</span>
            <div class="center2">
              <el-checkbox-group v-model="checkList">
              <el-checkbox label="0">无风险</el-checkbox>
              <el-checkbox label="1">低危</el-checkbox>
              <el-checkbox label="2">中危</el-checkbox>
              <el-checkbox label="3">高危</el-checkbox>
            </el-checkbox-group>
            </div>
              <span>),结合患者健康需求，确定(</span>
            <div>
              <el-radio-group v-model="radio">
              <el-radio label="0">可以</el-radio>
              <el-radio label="1">不可以</el-radio>
            </el-radio-group>
            </div>
            <span>)提供健康服务。</span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>备注：1.*表示有专业评估表格，详见附 表 1、2、3、4。 2.综合评估标准：取决于上述风险评估最高等级。</span>
      </div>
      <div class="tip tip1">
        <span>评估机构：</span>
        <span>评估医生：</span>
        <span>评估护士：</span>
        <span>评估时间：</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.incapacity {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .tip1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;

        .td {
          display: flex;
          height: 50px;
          color: #000;
          font-size: 18px;
        }

        .td-width {
          width: 210px;
        }

        .td-width1 {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 84px;
        }

        .td-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 95px;
          height: 100%;
        }
      }

      .flex1 {
        flex: 1;
      }

      .flex2 {
        flex: 2;
      }
    }
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .center1 {
    display: flex;
    align-items: center;
    padding-left: .225rem;
    box-sizing: border-box;
  }

  .center2 {
    display: flex;
    flex-wrap: wrap;
    padding-left: 18px;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
