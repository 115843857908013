<template>
  <div class="scald">
    <div class="title">
      <span>附表3 烫伤风险评估表</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>项目</span>
          </div>
          <div class="td flex1 border-right center center1">
            <span>评估内容</span>
          </div>
          <div class="td td-width border-right">
            <span>分值</span>
          </div>
          <div class="td td-width border-right center center1">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>年龄</span>
          </div>
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none" label="0">≥65岁</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>烫伤史</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>使用镇静、安眠药物</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>感觉障碍</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>2分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>意识障碍</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>自理能力</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">≤60分</el-radio>
              <el-radio label="1">&lt;60分</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>既往史</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">糖尿病、高血压、冠心病</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>采取热疗</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>患者及家属的认知</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">好</el-radio>
              <el-radio label="1">差</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>0分</span>
              </div>
              <div class="el-radio">
                <span>2分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td border-right flex1 center center1">
            <span>总分</span>
          </div>
          <div class="td td-width border-right">
            <span>12分</span>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准: 评分4 分为高度危险,评分 2-3 分为中度危险，评分&lt;2 分为低度 危险;自理能力评估标准 参照附件 4。</span>
      </div>
      <div class="tip">
        <span>2.烫伤风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1)宣传预防烫伤知识，告知老年人及家属发生烫伤的危险因囊和后果。</span>
      </div>
      <div class="tip">
        <span>(2)指导老年人及家属正确使用取暖设备，不要长时间接触温度超过体温的物品。患有糖尿病、脉管炎或中风后遗症、长期卧床的老年人要特别注意,比如: 电热毯不能通肯使用;热水袋温度不高于50°C。</span>
      </div>
      <div class="tip">
        <span>(3)指导老年人及家属正确使用生活设施。比如: 调节水温;热水瓶放置在固定且老年人不易触摸的地方。</span>
      </div>
      <div class="tip">
        <span>(4)正确使用医疗设备，使用热疗仪、烤灯时，密切监测温度变化，观察治疗部位的局部情况，不要随意调节仪器。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.scald {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: .10000px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(3), .tip:nth-child(4) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 5) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: .125rem;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 40px;
      }

      .tr-height2 {
        height: 108px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }

      .center1 {
        justify-content: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-bottom: 1px solid #000;
  }

  .el-radio:nth-child(n + 2) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-none {
    border-bottom: none;
  }

  .border-bottom {
    border-bottom: 1px solid #000 !important;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
