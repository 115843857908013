<template>
  <div class="life">
    <div class="title">
      <span>附表 2 精神状态与社会参与能力评分表</span>
    </div>
    <div class="thead">
      <div class="td td1 border-right">
        <span>评估项目</span>
      </div>
      <div class="td flex1 border-right">
        <span>具体评价指标</span>
      </div>
      <div class="td td2 border-right">
        <span>分值</span>
      </div>
      <div class="td td2">
        <span>评分</span>
      </div>
    </div>
    <div class="scroll">
      <div class="tr">
        <div class="td td1 border-right">
          <span>1.时间定向</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios1" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>2.空间定向</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios2" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>3.人物定向</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios3" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>4.记忆</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios4" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>5.攻击行为</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios5" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>6.抑郁症状</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios5" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>7.强迫行为</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios7" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>8.财务管理</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios8" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr tr1">
        <div class="td td1 border-right"></div>
        <div class="td flex1">
          <span>上述项目总分为40分，本次评估得分为</span>
          <el-input></el-input>
          <span>分</span>
        </div>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      radios1: Object.freeze(['时间观念（年、月、日、时）清楚', '时间观念有些下降，年 、月 、日清楚，但有时相差几天', '时间观念较差,年、月、日不清楚，可知上半年或下半年', '时间观念很差，年、月、日不清楚，可知上午或下午', '无时间观念']),
      radios2: Object.freeze(['', '可单独来往于近街,知道现住地的名称和方位，但不知回家路线', '只能单独在家附近行动,对现住地只知名称，不知道方位可单独出远门，能很快掌握新环境的方位', '只能在左邻右舍间串门，对现住地不知名称和方位', '不能单独外出']),
      radios3: Object.freeze(['知道周围人们的关系,知道祖孙、叔伯、姑姨、侄子侄女等称谓的意义;可分辨陌生人的大致年龄和身份，可用适当称呼', ' 只知家中亲密近亲的关系,不会分辨陌生人的大致年龄，不能称呼陌生人', '只能称呼家中人,或只能照样称呼，不知其关系，不辨 辈分', '只认识常同住的亲人,可称呼子女或孙子女，可辨熟人 和生人', '只认识保护人，不辨熟人和生人']),
      radios4: Object.freeze(['总是能够保持与社会、年龄所适应的长、短时记忆，能够完整的回忆', '出现轻度的记忆紊乱或回忆不能(不能回忆即时信息,3 个词语经过5分钟后仅能回忆0-1个)', '出现中度的记忆紊乱或回忆不能 (不能回忆近期记忆，不记得上顿饭吃了什么)', '出现重度的记忆紊乱或回忆不能(能回忆远期记忆,不记得自己的老朋友)', '记忆完全紊乱或完全不能对既往事物进行正确的回忆']),
      radios5: Object.freeze(['没出现', '每月出现一两次', '每周出现一两次', '过去3天里出现过一两次', '过去3天里天天出现']),
      radios7: Object.freeze(['无强迫症状（如反复洗手、关门、上厕所等）', '每月有1-2次强迫行为', '每周有 1-2次强迫行为', '过去3天里出现过一两次', '过去3天里天天出现']),
      radios8: Object.freeze(['金钱的管理、支配、使用，能独立完成', '因担心算错，每月管理约1000元', '因担心算错,每月管理约 300 元', '接触金钱机会少，主要由家属代管', '完全不接触金钱等']),
      fens: Object.freeze(['0', '1', '2', '3', '4'])
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.life {
  width: 99%;
  height: 100%;
  margin: 0 auto;
  // border: 1px red solid;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .thead {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: 1px solid #000;
    box-sizing: border-box;

    .td {
      height: 40px;
      line-height: 40px;
      color: #000;
      font-size: 18px;
      text-align: center;
    }

    .td1 {
      width: 115px;
    }

    .td2 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tr {
      display: flex;
      height: 200px;
      border: 1px solid #000;
      border-bottom: none;
      box-sizing: border-box;
    }

    .tr1 {
      height: 64px;

      .el-input {
        width: 80px;
        background-color: transparent;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #fff;
      }
    }

    .td {
      height: 100%;
      color: #000;
      font-size: 18px;
    }

    .td1 {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2px;
      box-sizing: border-box;
    }

    .td2 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }

    .el-radio-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .el-radio {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #000;
      }

      /deep/.el-radio__label {
        white-space: pre-wrap;
      }
    }

    .item {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #000;
      font-size: 18px;
    }
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
