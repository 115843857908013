<template>
  <div class="life">
    <div class="title">
      <span>附表 3 感知觉与沟通能力评分表</span>
    </div>
    <div class="thead">
      <div class="td td1 border-right">
        <span>评估项目</span>
      </div>
      <div class="td flex1 border-right">
        <span>具体评价指标</span>
      </div>
      <div class="td td2 border-right">
        <span>分值</span>
      </div>
      <div class="td td2">
        <span>评分</span>
      </div>
    </div>
    <div class="scroll">
      <div class="tr">
        <div class="td td1 border-right">
          <span>1.意识水平</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios1" :key="index" :class="{ 'border-bottom': index !== 3 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 3 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>2.视力 (若平日戴老花镜或近视镜，应在佩戴眼镜的情况下评估)</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios1" :key="index" :class="{ 'border-bottom': index !== 3 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 3 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>3.听力 (若平时佩能助听器应在佩戴助) 听器的情况下评)</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios1" :key="index" :class="{ 'border-bottom': index !== 3 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 3 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>4.沟通交流 (包括非语言沟)</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios1" :key="index" :class="{ 'border-bottom': index !== 3 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 3 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr tr1">
        <div class="td td1 border-right"></div>
        <div class="td flex1">
          <span>上述项目总分为12分，本次评估得分为</span>
          <el-input></el-input>
          <span>分</span>
        </div>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      radios1: Object.freeze(['神志清醒，对周围环境警觉', '踏睡,表现为睡眠状态过度廷长。当呼唤或推动其肢体时可唤醒,并能进行正确的交谈或执行指令，停止刺激 后又继续入睡', '昏睡，一般的外界刺激不能使其觉醒，给予较强烈的刺激时可有短时的意识清醒，霞后可简短回答提问，当刺 激减弱后又很快进入睡眠状态', '昏迷，处于浅昏迷时对疼痛刺 激有回避和痛苦表情 ; 处于深昏迷时 对刺激无反应(若评定为昏迷,直接评定为 重度失能，可不进行以下项目的评估)']),
      radios2: Object.freeze(['视力完好，能看清书报上的标准字体', '视力有限，看不清书报标准字', '辨认物体有困难，但眼睛能跟随物体移动，只能看到光 、颜色和形状', '没有视力，眼睛不能跟随物体移动']),
      radios3: Object.freeze(['可正常交谈，能听到电视、电话、门铃的声音', '在轻声说话或说话距离超过 2 米时听不清', '正常交流有些困难,需在安静的环境、大声说话或语速很慢，才能听到', '完全听不见']),
      radios4: Object.freeze(['无困难，能与他人正常沟通和交流', '能够表达自己的需要或理解别人的话，但需要增加时间', '勉强可与人交往，谈吐内容不清楚，表情不恰当 或给予帮助', '不能表达需要或理解他人的话']),
      fens: Object.freeze(['0', '1', '2', '3'])
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.life {
  width: 99%;
  height: 100%;
  margin: 0 auto;
  // border: 1px red solid;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .thead {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: 1px solid #000;
    box-sizing: border-box;

    .td {
      height: 40px;
      line-height: 40px;
      color: #000;
      font-size: 18px;
      text-align: center;
    }

    .td1 {
      width: 115px;
    }

    .td2 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tr {
      display: flex;
      height: 2.5rem;
      border: 1px solid #000;
      border-bottom: none;
      box-sizing: border-box;
    }

    .tr1 {
      height: 64px;

      .el-input {
        width: 80px;
        background-color: transparent;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #000;
        color: #000;
      }
    }

    .td {
      height: 100%;
      color: #000;
      font-size: 18px;
    }

    .td1 {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2px;
      box-sizing: border-box;
    }

    .td2 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }

    .el-radio-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .el-radio {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #000;
      }

      /deep/.el-radio__label {
        white-space: pre-wrap;
      }
    }

    .item {
      width: 100%;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #000;
      font-size: 18px;
    }
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
