<template>
  <div class="fall">
    <div class="title">
      <span>附表2 跌倒风险评估表</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>项目</span>
          </div>
          <div class="td flex1 border-right center center1">
            <span>评估内容</span>
          </div>
          <div class="td td-width border-right">
            <span>分值</span>
          </div>
          <div class="td td-width border-right center center1">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>跌倒史</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>25分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>两种及以上疾病</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>15分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height2 border-bottom">
          <div class="td td-width border-right">
            <span>使用助行器具</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">家具</el-radio>
              <el-radio class="border-bottom" label="1">拐杖/手杖/步行器</el-radio>
              <el-radio label="2">无/卧床/他人协助</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>30分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>15分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>静脉输液</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">有</el-radio>
              <el-radio label="1">无</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>20分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height2 border-bottom">
          <div class="td td-width border-right">
            <span>步态</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">缺失</el-radio>
              <el-radio class="border-bottom" label="1">虚弱</el-radio>
              <el-radio label="2">正常/卧床/不能活动</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>20分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>10分</span>
              </div>
              <div class="el-radio">
                <span>0分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>智力状态</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">正确认识自我能力</el-radio>
              <el-radio label="1">忘记能力有限</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>0分</span>
              </div>
              <div class="el-radio">
                <span>15分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td border-right flex1 center center1">
            <span>总分</span>
          </div>
          <div class="td td-width border-right">
            <span>150分</span>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准:10-24分为轻度危险;25-44分为中度危险;>45 分为高度危险。</span>
      </div>
      <div class="tip">
        <span>2.跌倒风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1)协助老年人改变体位时，宜做到醒后卧床 1 分钟再坐起、坐起 1 分钟再站立、站立 1 分钟再行走。</span>
      </div>
      <div class="tip">
        <span>(2)应指导老年人穿合体的衣服，不宜穿拖鞋外出。</span>
      </div>
      <div class="tip">
        <span>(3)应指导老年人正确使用助步器、拐杖等辅助器具。</span>
      </div>
      <div class="tip">
        <span>(4)房间内常用物品置于宜取位置，尽量固定摆放位置，减少通道障碍物。</span>
      </div>
      <div class="tip">
        <span>(5)沐浴时水温宜控制在39°c ~ 41°C， 沐浴时间宜控制在10 分钟~ 20 分钟。</span>
      </div>
      <div class="tip">
        <span>(6)地面应保持干燥无障碍，擦拭地面时应置警示牌。</span>
      </div>
      <div class="tip">
        <span>(7)沐浴时水温宜控制在39°c ~ 41°C， 沐浴时间宜控制在10 分钟~ 20 分钟。</span>
      </div>
      <div class="tip">
        <span>(8)浴室、厕所内应铺防滑垫;增设无障碍设施、报警装置。</span>
      </div>
      <div class="tip">
        <span>(9)感觉身体不适时减少外出，必要时须陪护人员陪同。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.fall {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(3), .tip:nth-child(4) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 5) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 40px;
      }

      .tr-height2 {
        height: 108px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }

      .center1 {
        justify-content: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-bottom: 1px solid #000;
  }

  .el-radio:nth-child(n + 2) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000 !important;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
