<template>
  <div class="raise">
    <div class="title">
      <span>老年人医养结合服务记录表</span>
    </div>
    <div class="head">
      <span>姓名：</span>
      <span>性别：</span>
      <span>年龄：</span>
      <span>联系电话：</span>
      <span>编号：</span>
    </div>
    <div class="tr">
      <div class="td td1 border-right">
        <span>服务日期</span>
      </div>
      <div class="td td1 border-right">
        <el-input></el-input>
        <span>年</span>
        <el-input></el-input>
        <span>月</span>
        <el-input></el-input>
        <span>日</span>
      </div>
      <div class="td td1">
        <el-input></el-input>
        <span>年</span>
        <el-input></el-input>
        <span>月</span>
        <el-input></el-input>
        <span>日</span>
      </div>
    </div>
    <div class="tr">
      <div class="td td1 border-right">
        <span>服务方式</span>
      </div>
      <div class="td td1 border-right">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">门诊</el-checkbox>
          <el-checkbox label="1">家庭</el-checkbox>
          <el-checkbox label="2">电话</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="td td1">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="0">门诊</el-checkbox>
          <el-checkbox label="1">家庭</el-checkbox>
          <el-checkbox label="2">电话</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="tr">
      <div class="td td2 border-right">
        <div class="left border-right">
          <span>健</span>
          <span>康</span>
          <span>情</span>
          <span>况</span>
        </div>
        <div class="right">
          <el-checkbox-group v-model="checkList">
            <el-checkbox v-for="(item, index) in checks" :label="(index + 1) + ''" :key="index">{{ item }}</el-checkbox>
          </el-checkbox-group>
        </div>
      </div>
      <div class="td td2 td5 border-right">
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(item, index) in checks" :label="(index + 1) + ''" :key="index">{{ item }}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="td td2 td5">
        <el-checkbox-group v-model="checkList">
          <el-checkbox v-for="(item, index) in checks" :label="(index + 1) + ''" :key="index">{{ item }}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="tr">
      <div class="td td3 border-right">
        <div class="left border-right">
          <span>服</span>
          <span>务</span>
          <span>内</span>
          <span>容</span>
          <span>记</span>
          <span>录</span>
        </div>
        <div class="right right1">
          <div class="right-td border-bottom">
            <span>血压测量(mmHg)</span>
          </div>
          <div class="right-td border-bottom">
            <span>末梢血血糖检测(mmol/L)</span>
          </div>
          <div class="right-td border-bottom">
            <span>康复指导</span>
          </div>
          <div class="right-td border-bottom">
            <span>护理技能指导</span>
          </div>
          <div class="right-td border-bottom">
            <span>保健咨询</span>
          </div>
          <div class="right-td">
            <span>营养改善指导</span>
          </div>
        </div>
      </div>
      <div class="td td3 td4 border-right">
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td">
        </div>
      </div>
      <div class="td td3 td4">
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td border-bottom">
        </div>
        <div class="right-td">
        </div>
      </div>
    </div>
    <div class="tr">
      <div class="td td1 border-right">
        <span>服务医师或团队签字</span>
      </div>
      <div class="td td1 border-right">
      </div>
      <div class="td td1">
      </div>
    </div>
    <div class="tr">
      <div class="td td1 border-right">
        <span>预约下次随访时间</span>
      </div>
      <div class="td td1 border-right">
      </div>
      <div class="td td1">
      </div>
    </div>
    <div class="tr border-bottom">
      <div class="td td1 border-right">
        <span>备注</span>
      </div>
      <div class="td td1 border-right">
      </div>
      <div class="td td1">
      </div>
    </div>
    <div class="button">
      <el-button type="primary" size="small">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checks: Object.freeze(['无', '高血压', '糖尿病', '血脂异常', '消瘦', '贫血', '超重、肥胖', '视力异常', '听力异常', '失能', '其他']),
      checkList: ['0']
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.raise {
  width: 90%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 35px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    font-size: 20px;
  }

  .tr {
    display: flex;
    align-items: center;
    border: 1px solid #000;
    border-bottom: none;
    box-sizing: border-box;

    .td {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;
      font-size: 18px;

      .el-input {
        width: 80px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        color: #000;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 37px;
        height: 100%;
      }

      .right {
        display: flex;
        align-items: center;
        width: calc(100% - 37px);
        padding: 0 10px;
        height: 100%;
        box-sizing: border-box;

        .el-checkbox {
          width: 38%;
        }
      }

      .right1 {
        flex-direction: column;
        padding: 0;
      }
    }

    .right-td {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }

    .td1 {
      display: flex;
      height: 40px;
      text-align: center;
    }

    .td2 {
      height: 152px;
      box-sizing: border-box;
    }

    .td3 {
      height: 240px;
    }

    .td4 {
      display: flex;
      flex-direction: column;
    }

    .el-checkbox-group {
      margin-left: 10px;
    }
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
