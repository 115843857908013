<template>
  <div class="scald">
    <div class="title">
      <span>附表7 吞咽功能评估</span>
    </div>
    <div class="scroll">
      <div class="title">
        <span>让患者端坐，喝下30ml温开水，观察所需时间及呛咳情况:</span>
      </div>
      <div class="table">
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-right center center1">
            <span>项目</span>
          </div>
          <div class="td td-width border-right">
            <span>分值</span>
          </div>
          <div class="td td-width border-right center center1">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">能顺利地1 次将水咽下</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1级</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none  el-radio1" label="0">分2 次以上，能不呛咳地咽下</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>2级</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">能1次咽下，但有呛咳</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>3级</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">分2 次以上咽下，也有呛咳</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>4级</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">频繁呛咳，不能全部咽下</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>5级</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准:5 级为高度危险 ;3-4级为中度危险;1 级 、5 秒以上时间咽下,2 级为低度危险 ;1 级 、5 秒之内时间咽下为正常。</span>
      </div>
      <div class="tip">
        <span>2.吞咽功能障碍指导内容:</span>
      </div>
      <div class="tip">
        <span>(1) 进食体位: 取端坐位，头部稍向前倾，颈部可垫软枕。</span>
      </div>
      <div class="tip">
        <span>(2) 进食时根据患者一口量,吃完一口再吃下一口,观察进食量,呛咳情况,肺部感染情况 。如出现呼吸困难,立即停止喂食。</span>
      </div>
      <div class="tip">
        <span>(3) 指导患者行 舌头的被动运动，使用舌肌锻炼器，将舌头向前后、左右、上下各个方向牵拉( 每日三次,每次5个循环)循序渐进。</span>
      </div>
      <div class="tip">
        <span>(4) 喂食后指导患者漱口 ，保持坐位 30 分钟 -60分钟 。</span>
      </div>
      <div class="tip">
        <span>(5) 食物的选择:正常者采取正常饮食;轻度吞咽障碍给予半流质饮食,如烂面条 、八宝粥等,并配合少量饮水 ;中度吞咽障碍给予康复训练,如吞咽功能训练、摄食训练等;重度吞咽障碍患者首选不需咀嚼的密度均匀又不易出现误咽的浓桐食物，如蛋羹、水果酱馒头牛奶糊、米糊等 ; 必要时采取插胃管鼻饲。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.scald {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: .10000px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(4), .tip:nth-child(5) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 6) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 40px;
      }

      .tr-height2 {
        height: 108px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }

      .center1 {
        justify-content: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-bottom: 1px solid #000;
  }

  .el-radio1 {
    justify-content: flex-start;
    padding-left: 10px !important;
    box-sizing: border-box;
  }

  .el-radio:nth-child(n + 2) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-none {
    border-bottom: none;
  }

  .border-bottom {
    border-bottom: 1px solid #000 !important;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
</style>
