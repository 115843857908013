<template>
  <div class="scald">
    <div class="title">
      <span>附表5 坠床风险评估表</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-right center center1">
            <span>项目</span>
          </div>
          <div class="td td-width border-right">
            <span>分值</span>
          </div>
          <div class="td td-width border-right center center1">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">年龄≥65岁</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none  el-radio1" label="0">感觉异常（视觉异常、听觉异常）</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">认知异常</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">精神状态（烦躁、抽搐、重度抑郁）</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>4分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">活动需要（人或物）协助</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">使用镇静、镇痛、利尿、降压、降糖药物</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-none border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio class="border-none el-radio1" label="0">有跌倒史或坠床史</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio border-none">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td border-right flex1 center center1">
            <span>总分</span>
          </div>
          <div class="td td-width border-right">
            <span>12分</span>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准: 评分 24 分为高度危险; 评分 2-3分为中度危险; 评分&lt; 2 分为低度危险。</span>
      </div>
      <div class="tip">
        <span>2.坠床风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1) 告知患者及家属可能存在的风险性，加以警示;</span>
      </div>
      <div class="tip">
        <span>(2) 房间内保持光线充足，常用物品置于易取之处;</span>
      </div>
      <div class="tip">
        <span>(3) 起床活动时动作宜媛慢，先平躺，再慢慢起身渐进下床;</span>
      </div>
      <div class="tip">
        <span>(4)头晕、头痛时不宜起身活动，须 24 小时留陪护人员;</span>
      </div>
      <div class="tip">
        <span>(5)必要时使用床档及约束带;</span>
      </div>
      <div class="tip">
        <span>(6)高度风险患者的 床挡高度以高出褥垫35CM 为宜;</span>
      </div>
      <div class="tip">
        <span>(7) 床的高度以患者小腿长度 100-120% 为宜 (坐时双脚踏实着地) ，床若有轮须制动。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.scald {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(3), .tip:nth-child(4) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 5) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 40px;
      }

      .tr-height2 {
        height: 108px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }

      .center1 {
        justify-content: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-bottom: 1px solid #000;
  }

  .el-radio1 {
    justify-content: flex-start;
    padding-left: 10px !important;
    box-sizing: border-box;
  }

  .el-radio:nth-child(n + 2) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-none {
    border-bottom: none;
  }

  .border-bottom {
    border-bottom: 1px solid #000 !important;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
