<template>
  <div class="raise">
    <div class="title">
      <span>老年健康与医养结合服务管理情况统计表</span>
    </div>
    <div class="head">
      <span class="border"></span>
      <span>区县（盖章）</span>
    </div>
    <el-table :data="tableData" border height="190" style="width: 100%">
      <el-table-column label="65岁及以上老年人医养结合服务情况">
        <el-table-column prop="type" label="辖区内65岁以上老年人口数（人）">
        </el-table-column>
        <el-table-column prop="type" label="年内 65 岁以上老年人接受医养结合服务人数（人）">
        </el-table-column>
        <el-table-column prop="type" label="65 岁及以上老年人医养结合服务率 (%)">
        </el-table-column>
      </el-table-column>
      <el-table-column label="65岁以上失能老年人健康服务情况">
        <el-table-column prop="type" label="辖区内接受健康评估的65 岁以上失能老年人人数（人）">
        </el-table-column>
        <el-table-column prop="type" label="年内接受健康服务的65岁以上失能老年人人数(人)">
        </el-table-column>
        <el-table-column prop="type" label="65 岁以上失能老年人健康服务率(%)">
        </el-table-column>
      </el-table-column>
    </el-table>
    <div class="tip">
      <span>备注 :“65 岁及以上老年人医养结合服务情况”中“辖区内 65 岁以上老年人口数"一栏与基本公共卫生服务-老年人健康管理项目指标“年内辖区内 65岁及以上常住居民数”保持一致。</span>
    </div>
    <div class="tip1">
      <span>填报人：</span>
      <span>联系电话：</span>
      <span>填报时间：</span>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checks: Object.freeze(['无', '高血压', '糖尿病', '血脂异常', '消瘦', '贫血', '超重、肥胖', '视力异常', '听力异常', '失能', '其他']),
      checkList: ['0'],
      tableData: [{
        type: '健康风指导'
      }]
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.raise {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  // border: 1px red solid;

  .title {
    text-align: center;
    color: #000;
    font-size: 35px;
  }

  .head {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    font-size: 20px;

    .border {
      width: 100px;
      height: 24px;
      border-bottom: 1px solid #000;
    }
  }

  /deep/.el-table__cell {
    text-align: center;
    color: #000;
  }

  .tip {
    margin: 5px 0;
    color: #000;
    font-size: 18px;
  }

  .tip1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80%;
    margin: 5px auto;
    color: #000;
    font-size: 18px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
