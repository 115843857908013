<template>
  <div class="old">
    <div class="old-tab">
      <div v-for="(item, index) in tabs" :key="index">
        <div class="item" :class="{ item1: tabIndex === index }" @click="tabClick(index)">{{ item }}</div>
        <div v-if="index === 3 && tabIndex === 3" class="item-box">
          <div v-for="(items, indexs) in tabs1" :key="indexs" class="item-font" :class="{ 'item-font1': indexs === tabIndex1 }" @click="tabClick1(indexs)">{{ items }}</div>
        </div>
        <div v-if="(index === 5 && tabIndex === 5)" class="item-box">
          <div v-for="(items, indexs) in tabs2" :key="indexs" class="item-font" :class="{ 'item-font1': indexs === tabIndex2 }" @click="tabClick2(indexs)">{{ items }}</div>
        </div>
      </div>
    </div>
    <div class="old-box">
      <!-- 医养结合服务记录表 -->
      <Raise v-if="tabIndex === 0"></Raise>
      <!-- 健康服务记录表 -->
      <HealthServe v-else-if="tabIndex === 1"></HealthServe>
      <!-- 健康医养统计表 -->
      <Count v-else-if="tabIndex === 2"></Count>
      <!-- 能力评估表 -->
      <Ability v-else-if="tabIndex === 3 && tabIndex1 === -1"></Ability>
      <!-- 日常生活能力评价表 -->
      <Life v-else-if="tabIndex === 3 && tabIndex1 === 0"></Life>
      <!-- 精神状态与社会能力表 -->
      <Spirit v-else-if="tabIndex === 3 && tabIndex1 === 1"></Spirit>
      <!-- 感知觉与沟通能力评分表 -->
      <Perception v-else-if="tabIndex === 3 && tabIndex1 === 2"></Perception>
      <!-- 老年综合征罹患评估情况 -->
      <Aged v-else-if="tabIndex === 3 && tabIndex1 === 3"></Aged>
      <!-- 失能老年人健康评估 -->
      <Incapacity v-else-if="tabIndex === 4"></Incapacity>
      <!-- 压疮风险评估表 -->
      <PressureSore v-else-if="tabIndex === 5 && tabIndex2 === 0"></PressureSore>
      <!-- 跌倒风险评估表 -->
      <Fall v-else-if="tabIndex === 5 && tabIndex2 === 1"></Fall>
      <!-- 烫伤风险评估表 -->
      <Scald v-else-if="tabIndex === 5 && tabIndex2 === 2"></Scald>
      <!-- 窒息/误吸风险评估表 -->
      <Asphyxia v-else-if="tabIndex === 5 && tabIndex2 === 3"></Asphyxia>
      <!-- 坠床风险评估表 -->
      <FallBed v-else-if="tabIndex === 5 && tabIndex2 === 4"></FallBed>
      <!-- 脑卒中风险评估表 -->
      <Brain v-else-if="tabIndex === 5 && tabIndex2 === 5"></Brain>
      <!-- 吞咽评估表 -->
      <Swallow v-else-if="tabIndex === 5 && tabIndex2 === 6"></Swallow>
      <!-- 管路滑脱风险评估 -->
      <Piping v-else-if="tabIndex === 5 && tabIndex2 === 7"></Piping>
    </div>
  </div>
</template>

<script>
import Raise from './raise.vue'
import HealthServe from './healthServe.vue'
import Count from './count.vue'
import Ability from './ability.vue'
import Life from './life.vue'
import Spirit from './spirit.vue'
import Perception from './perception.vue'
import Aged from './aged.vue'
import Incapacity from './incapacity.vue'
import PressureSore from './pressureSore.vue'
import Fall from './fall.vue'
import Scald from './scald.vue'
import Asphyxia from './asphyxia.vue'
import FallBed from './fallBed.vue'
import Brain from './brain.vue'
import Swallow from './swallow.vue'
import Piping from './piping.vue'
export default {
  components: {
    Raise,
    HealthServe,
    Count,
    Ability,
    Life,
    Spirit,
    Perception,
    Aged,
    Incapacity,
    PressureSore,
    Fall,
    Scald,
    Asphyxia,
    FallBed,
    Brain,
    Swallow,
    Piping
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['医养结合服务记录表', '健康服务记录表', '健康医养统计表', '能力评估表', '失能老年人健康评估', '老年人风险评估总表']),
      tabIndex1: -1,
      tabs1: Object.freeze(['日常生活能力评价表', '精神状态与社会能力表', '感知与沟通能力评价表', '老年综合征罹患评估情况']),
      tabIndex2: -1,
      tabs2: Object.freeze(['压疮风险评估表', '跌倒风险评估表', '烫伤风险评估表', '窒息/误吸风险评估表', '坠床风险评估表', '脑卒中风险评估表', '吞咽评估表', '管路滑脱风险评估'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      if (index === 3) {
        this.tabIndex1 = -1
      }
    },
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    tabClick2 (index) {
      this.tabIndex2 = index
    }
  }
}
</script>

<style lang="less" scoped>
.old {
  display: flex;
  width: 100%;
  height: 100%;

  .old-tab {
    display: flex;
    flex-direction: column;
    align-items: center;

    .item {
      width: 2.5rem;
      height: .5125rem;
      line-height: .5125rem;
      margin-top: .125rem;
      font-size: .225rem;
      text-align: center;
      color: #fff;
      background-image: url('../../img/ssjk/inactive.png');
      background-size: 100% 100%;
      cursor: pointer;
    }

    .item1 {
      background-image: url('../../img/ssjk/active.png');
    }

    .item-box {
      display: flex;
      flex-direction: column;
      width: 2.5rem;

      .item-font {
        width: 100%;
        height: .45rem;
        line-height: .45rem;
        text-align: center;
        background-color: #74899C;
        color: #fff;
        font-size: .2rem;
        cursor: pointer;
        white-space: nowrap;
      }

      .item-font1 {
        background-color: #105A97;
      }
    }
  }

  .old-box {
    width: calc(100% - 3rem);
    height: 100%;
    margin-left: .25rem;
  }
}
</style>
