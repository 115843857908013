<template>
  <div class="incapacity">
    <div class="title">
      <span>附表4 窒息/误吸风险评估表</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>项目</span>
          </div>
          <div class="td flex1 border-right">
            <div class="td-item border-bottom">
              <span>评估计分标准</span>
            </div>
            <div class="td-item td-item1">
              <div class="td-item border-right">
                <span>1分</span>
              </div>
              <div class="td-item border-right">
                <span>2分</span>
              </div>
              <div class="td-item border-right">
                <span>3分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>年龄</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">&lt;65岁</el-radio>
              <el-radio label="2">65-80岁</el-radio>
              <el-radio label="3">>80岁</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>神志</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">清醒</el-radio>
              <el-radio label="2">清醒+镇静</el-radio>
              <el-radio label="3">昏迷</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>痰</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">少</el-radio>
              <el-radio label="2">多+稠</el-radio>
              <el-radio label="3">多+稀薄</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>合并老年痴呆、脑血管意外、重症肌无力、帕金森氏症</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">无</el-radio>
              <el-radio label="2">1种</el-radio>
              <el-radio label="3">1种以上</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>饮食</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">禁食</el-radio>
              <el-radio label="2">普食</el-radio>
              <el-radio label="3">流质或半流质</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>进食 体位</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">半卧≥30°</el-radio>
              <el-radio label="2">半卧&lt;30°</el-radio>
              <el-radio label="3">平卧</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>饮水实验</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">1级</el-radio>
              <el-radio label="2">2级</el-radio>
              <el-radio label="3">3级及以上</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>人工气道机械通气</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">无</el-radio>
              <el-radio label="2">有</el-radio>
              <el-radio class="radio" disabled label="3">——</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td border-right flex1 center center1">
            <span>总分</span>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准: 19-23分为高度危险，13-18分为中度危险，10-12分为低度危险</span>
      </div>
      <div class="tip">
        <span>2.饮水实验见 附表 7</span>
      </div>
      <div class="tip">
        <span>3.窒息/误吸风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1)患者吞咽反射迟钝给予软食 ，必要时流质半流质 ，避免带骨刺食物</span>
      </div>
      <div class="tip">
        <span>(2)对痰液粘稠者或不能自行咳痰的老年人，应及时吸痰，定时予以翻身、叩 背，遵医嘱给予雾化吸入，促进排痰。</span>
      </div>
      <div class="tip">
        <span>(3)卧床老年人出现呕吐时，应立即将其头偏向一侧，吸净口腔内食物。</span>
      </div>
      <div class="tip">
        <span>(4)给老年人服用药物或静脉用药时，应观察药物反应,有无喉头水肿等症状,发现异常及时报告医生并给予处理。</span>
      </div>
      <div class="tip">
        <span>(5)有室息风险因素者宜床旁备好负压吸引装置</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.incapacity {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(3), .tip:nth-child(4), .tip:nth-child(5) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 6) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 80px;
      }

      .tr-height1 {
        height: 40px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 40px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }

      .center1 {
        justify-content: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-right: 1px solid #000;
  }

  .el-radio:nth-child(4) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
