<template>
  <div class="life">
    <div class="title">
      <span>附表 4 老年综合征罹患评估情况</span>
    </div>
    <div class="thead">
      <div class="td flex1 border-right">
        <span>症状</span>
      </div>
      <div class="td flex1 border-right">
        <span>询问情况</span>
      </div>
      <div class="td flex1 border-right">
        <span>评分标志</span>
      </div>
      <div class="td td1">
        <span>评分</span>
      </div>
    </div>
    <div class="scroll">
      <div class="tr">
        <div class="td flex1 border-right">
          <span>1.痴呆</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 5 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>2.抑郁症</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 5 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>3.失眠症</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 3 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>4.尿失禁</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 2 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>5.老年帕金森综合征</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 5 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>6.谵妄（30天内）</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 3 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>7.晕厥（30天内）</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 4 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>8.走失（30天内）</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 5 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>9.噎食（30天内）</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 3 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>10.自杀（30天内）</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 5 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td flex1 border-right">
          <span>11.跌倒（30天内）</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
            <el-radio label="2">不清楚</el-radio>
          </el-radio-group>
        </div>
        <div class="td flex1 border-right">
          <span>“是” 评 3 分，“否” 评 0 分</span>
        </div>
        <div class="td td1">
          <span></span>
        </div>
      </div>
      <div class="tr border-bottom">
        <div class="td td2 flex1 border-right">
          <span>12.其他</span>
          <el-input></el-input>
        </div>
        <div class="td td2 flex1 border-right"></div>
        <div class="td td2 flex1 border-right">
          <span>“有其他情况” 评 2 分，“无其他情况” 评 0 分</span>
        </div>
        <div class="td td2 td1">
          <span></span>
        </div>
      </div>
      <div class="tr">
        <div class="td td2 flex1">
          <span>上述项目总分为 25 分</span>
          <span>，本次评估得分为</span>
          <el-input></el-input>
          <span>分</span>
        </div>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0'
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.life {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .thead {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: 1px solid #000;
    box-sizing: border-box;

    .td {
      height: 40px;
      line-height: 40px;
      color: #000;
      font-size: 18px;
      text-align: center;
    }

    .td1 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tr {
      display: flex;
      align-items: center;
      border: 1px solid #000;
      box-sizing: border-box;
      border-bottom: none;

      .td {
        display: flex;
        align-items: center;
        height: 40px;
        padding-left: 8px;
        box-sizing: border-box;
        color: #000;
        font-size: 18px;
      }

      .td1 {
        width: 100px;
      }

      .td2 {
        height: 80px;
      }

      .flex1 {
        flex: 1;
      }

      .el-radio {
        color: #000;
      }

      .el-input {
        width: 120px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #000;
        box-sizing: border-box;
        color: #000;
      }
    }
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
</style>
