<template>
  <div class="raise">
    <div class="title">
      <span>老年人医养结合服务记录表</span>
    </div>
    <div class="head">
      <span>姓名：</span>
      <span>性别：</span>
      <span>年龄：</span>
      <span>联系电话：</span>
      <span>编号：</span>
    </div>
    <el-table :data="tableData" border height="580" style="width: 100%" :span-method="objectSpanMethod">
      <el-table-column prop="type" label="服务分类" width="62">
      </el-table-column>
      <el-table-column prop="server1" label="服务项目"  width="240">
      </el-table-column>
      <el-table-column prop="server2" label="服务内容">
      </el-table-column>
      <el-table-column prop="server3" label="是否提供服务" width="150">
        <template slot-scope="scope">
          <el-radio-group v-model="scope.row.server3">
            <el-radio label="0">是</el-radio>
            <el-radio label="1">否</el-radio>
          </el-radio-group>
        </template>
      </el-table-column>
    </el-table>
    <div class="tip">
      <span>服务医师或团队签字：</span>
    </div>
    <div class="button">
      <el-button type="primary" size="small">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      checks: Object.freeze(['无', '高血压', '糖尿病', '血脂异常', '消瘦', '贫血', '超重、肥胖', '视力异常', '听力异常', '失能', '其他']),
      checkList: ['0'],
      tableData: [{
        type: '健康风险指导',
        server1: '压疮预防及护理指导',
        server2: '告知患者及家属可能发生的风险、不良后果及预防措施:指导惠者及家属正确应对压疮问题; 指导家属如何预防压疮复发。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '跌倒预防及居家照料指导',
        server2: '告知患者及家属可能发生的风险 、不良后果及预防措施应指导患者及家属正确应对跌倒问题。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '烫伤预防指导',
        server2: '告知烫伤常见风险因素。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '窒息/误吸风险预防及护理指导',
        server2: '根据测评结果给予相对应的护理指导; 指导家属动态的评估观察记录。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '坠床预防及护理指导',
        server2: '告知患者及家属坠床的常见风险因素; 有效指导家属掌握防控措施。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '脑卒中健康指导',
        server2: '告知患者及家属脑卒中危险因素。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '吞咽功能障碍健康指导',
        server2: '根据进餐护理要求及鼻饲护理要求，指导家属给予动态评估观察记录。',
        server3: ''
      }, {
        type: '健康风险指导',
        server1: '管路滑脱风险评估及护理指导',
        server2: '告知患者及家属管路滑脱常见风险因素；指导家属动态评估观察记录。',
        server3: ''
      }, {
        type: '护理操作',
        server1: '血压/血糖测量',
        server2: '指导患者及家属正确测量血压/血糖方法;告知血压/血糖正常范围值、血压/血糖测量注意事项。',
        server3: ''
      }, {
        type: '护理操作',
        server1: '换药',
        server2: '指导家属换药术后常规护理要点，教会家属如何护理患者。',
        server3: ''
      }, {
        type: '护理操作',
        server1: '血压/血糖测量',
        server2: '指导家属常规护理要点，预防烫伤、冻伤发生。',
        server3: ''
      }, {
        type: '',
        server1: '口腔护理',
        server2: '指导家属常规护理要点，确保患者口腔清洁、无异味。',
        server3: ''
      }, {
        type: '',
        server1: '造口护理',
        server2: '指导家属常规护理要点。',
        server3: ''
      }, {
        type: '',
        server1: '压疮/坠积性肺炎护理指导',
        server2: '指导家属常规护理要点; 教会患者正确的按摩、翻身及拍背等方法。',
        server3: ''
      }, {
        type: '',
        server1: '慢性病管理',
        server2: '指导患者及家属常见慢性病的预防、保健、治疗、康复护理知识。',
        server3: ''
      }, {
        type: '生活指导',
        server1: '健康教育',
        server2: '指导患者及家属养成正确的生活方式 ，掌握健康 生活方式指导技巧和知识 ; 提供常见病、多发病 和传染病相关问题咨询与预防指导。',
        server3: ''
      }, {
        type: '生活指导',
        server1: '清洁卫生指导',
        server2: '指导患者及家属便秘与腹泻 、排尿困难与压力性 尿失禁护理方法。',
        server3: ''
      }, {
        type: '生活指导',
        server1: '睡眠照料指导',
        server2: '分析造成非正常睡眠的特殊原因井予以解决; 指导患者及家属相关的疼痛护理方法和松弛肌肉方法。',
        server3: ''
      }, {
        type: '生活指导',
        server1: '居家消毒指导',
        server2: '指导家属掌握常用物理消毒方法和传染病隔离知识。',
        server3: ''
      }, {
        type: '生活指导',
        server1: '观察指导',
        server2: '分析患者居家环境情况井给予指导(内环境、外环境评估)。',
        server3: ''
      }, {
        type: '康复服务',
        server1: '肢体康复',
        server2: '指导家属进行简单物理训练。',
        server3: ''
      }, {
        type: '心理支持',
        server1: '心理健康教育',
        server2: '向老年人宣讲心理保健知识，对老年人忧虑、恐 惧、焦虑等不良情绪进行疏导，与老年人进行情感交流并予以心理支持。',
        server3: ''
      }, {
        type: '药事服务',
        server1: '用药指导',
        server2: '告知用药注意事项、禁忌证、服药适宜时间、潜在的不良反应等。',
        server3: ''
      }]
    }
  },
  methods: {
    objectSpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if (rowIndex === 0) {
          return {
            rowspan: 8,
            colspan: 1
          }
        } else if (rowIndex === 8) {
          return {
            rowspan: 3,
            colspan: 1
          }
        } else if (rowIndex === 11) {
          return {
            rowspan: 4,
            colspan: 1
          }
        } else if (rowIndex === 15) {
          return {
            rowspan: 5,
            colspan: 1
          }
        } else if (rowIndex === 20) {
          return {
            rowspan: 1,
            colspan: 1
          }
        } else if (rowIndex === 21) {
          return {
            rowspan: 1,
            colspan: 1
          }
        } else if (rowIndex === 22) {
          return {
            rowspan: 1,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.raise {
  width: 100%;
  height: 100%;
  padding: 0 10px;
  box-sizing: border-box;

  .title {
    text-align: center;
    color: #000;
    font-size: 35px;
  }

  .head {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #000;
    font-size: 20px;
  }

  .el-table {
    color: #000;
    background-color: transparent;
  }

  .tip {
    color: #000;
    font-size: 18px;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
</style>
