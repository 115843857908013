var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"life"},[_vm._m(0),_c('div',{staticClass:"scroll"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"table"},[_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('div',{staticClass:"tr tr1 border-bottom"},[_vm._m(8),_c('div',{staticClass:"td td2 td3 flex"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("日常生活活动能力")]),_c('el-input'),_c('span',[_vm._v("分；")])],1),_c('div',{staticClass:"item"},[_c('span',[_vm._v("精神状态与社会参与能力")]),_c('el-input'),_c('span',[_vm._v("分；")])],1),_c('div',{staticClass:"item"},[_c('span',[_vm._v("感知觉与沟通能力")]),_c('el-input'),_c('span',[_vm._v("分；")])],1),_c('div',{staticClass:"item"},[_c('span',[_vm._v("老年综合征罹患情况")]),_c('el-input'),_c('span',[_vm._v("分；")])],1)])]),_c('div',{staticClass:"tr tr2"},[_vm._m(9),_c('div',{staticClass:"td flex1 flex"},[_c('el-checkbox-group',{model:{value:(_vm.checkList),callback:function ($$v) {_vm.checkList=$$v},expression:"checkList"}},[_c('el-checkbox',{attrs:{"label":"0"}},[_vm._v("能力完好")]),_c('el-checkbox',{attrs:{"label":"1"}},[_vm._v("轻度失能")]),_c('el-checkbox',{attrs:{"label":"2"}},[_vm._v("中度失能")]),_c('el-checkbox',{attrs:{"label":"3"}},[_vm._v("重度失能")])],1)],1)])]),_vm._m(10)]),_c('div',{staticClass:"button"},[_c('el-button',{attrs:{"type":"primary","size":"small"}},[_vm._v("确定")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("老年人能力评估标准（试行）")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('span',[_vm._v("根据对老年人日常生活活动能力、精神状态与社会参与能力、感知觉与沟通储力3 个维度评估的评分情况，将老年人能力评定 为 4 个等级，即完好、轻度受损、中度受损重度受损。 先根据 日常生活活动能力得分情况确定区间，再分别结合精神状态与社会参与能力以及感知觉与沟通能力得分情况确定老年人能力等级，以最严重的老年人能力等级为准 。老年综合征罹患情况是作 为失能单独晋级,老年罹患综合征评5-9分能力等级提升 1 级 ; 10-19 分能力等级提升 2 级,20 分及以上直接判断重度失能。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("老年人能力评定总表")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr1 border-bottom"},[_c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("日常生活活动能力")])]),_c('div',{staticClass:"td flex1 border-right"},[_c('div',{staticClass:"td-item border-bottom"},[_c('span',[_vm._v("精神状态与社会参与能力")])]),_c('div',{staticClass:"td-item"},[_c('div',{staticClass:"td-item-flex border-right"},[_c('span',[_vm._v("0 分")])]),_c('div',{staticClass:"td-item-flex border-right"},[_c('span',[_vm._v("1-8 分")])]),_c('div',{staticClass:"td-item-flex border-right"},[_c('span',[_vm._v("9-24 分")])]),_c('div',{staticClass:"td-item-flex"},[_c('span',[_vm._v("25-40 分")])])])]),_c('div',{staticClass:"td flex1"},[_c('div',{staticClass:"td-item border-bottom"},[_c('span',[_vm._v("感知觉与沟通能力")])]),_c('div',{staticClass:"td-item"},[_c('div',{staticClass:"td-item-flex border-right"},[_c('span',[_vm._v("0 分")])]),_c('div',{staticClass:"td-item-flex border-right"},[_c('span',[_vm._v("1-8 分")])]),_c('div',{staticClass:"td-item-flex border-right"},[_c('span',[_vm._v("9-24 分")])]),_c('div',{staticClass:"td-item-flex"},[_c('span',[_vm._v("25-40 分")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr2 border-bottom"},[_c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("0 分")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("完好")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("完好")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("完好")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("完好")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2"},[_c('span',[_vm._v("轻度受损")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr2 border-bottom"},[_c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("1-20 分")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("轻度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2"},[_c('span',[_vm._v("中度受损")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr2 border-bottom"},[_c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("21-40 分")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("中度受损")])]),_c('div',{staticClass:"td td2"},[_c('span',[_vm._v("重度受损")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tr tr2 border-bottom"},[_c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("41-60 分")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2 border-right"},[_c('span',[_vm._v("重度受损")])]),_c('div',{staticClass:"td td2"},[_c('span',[_vm._v("重度受损")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("评分得分")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"td td1 border-right"},[_c('span',[_vm._v("评估结果")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tip"},[_c('span',[_vm._v("备注：老年人日常生活活动能力、精神状态与社会参与能力、感知觉与沟通能力、老年综合征罹患情况评分分别见附 表 1、2、3、4。")])])
}]

export { render, staticRenderFns }