<template>
  <div class="incapacity">
    <div class="title">
      <span>附表6 脑卒中风险评估（改良的弗明汉卒中量表）</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>项目</span>
          </div>
          <div class="td flex1 border-right">
            <div class="td-item border-bottom">
              <span>男性评估计分标准</span>
            </div>
            <div class="td-item td-item1">
              <div class="td-item border-right">
                <span>0分</span>
              </div>
              <div class="td-item border-right">
                <span>1分</span>
              </div>
              <div class="td-item border-right">
                <span>2分</span>
              </div>
              <div class="td-item border-right">
                <span>3分</span>
              </div>
              <div class="td-item border-right">
                <span>4分</span>
              </div>
              <div class="td-item border-right">
                <span>5分</span>
              </div>
              <div class="td-item border-right">
                <span>6分</span>
              </div>
              <div class="td-item border-right">
                <span>7分</span>
              </div>
              <div class="td-item border-right">
                <span>8分</span>
              </div>
              <div class="td-item border-right">
                <span>9分</span>
              </div>
              <div class="td-item">
                <span>10分</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>年龄（岁）</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>54-56</span>
              </div>
              <div class="el-radio">
                <span>57-59</span>
              </div>
              <div class="el-radio">
                <span>60-62</span>
              </div>
              <div class="el-radio">
                <span>63-65</span>
              </div>
              <div class="el-radio">
                <span>66-68</span>
              </div>
              <div class="el-radio">
                <span>69-72</span>
              </div>
              <div class="el-radio">
                <span>73-75</span>
              </div>
              <div class="el-radio">
                <span>76-78</span>
              </div>
              <div class="el-radio">
                <span>79-81</span>
              </div>
              <div class="el-radio">
                <span>82-84</span>
              </div>
              <div class="el-radio border-none">
                <span>85</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>未治疗收缩压(mmHg)</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>97-105</span>
              </div>
              <div class="el-radio">
                <span>106-115</span>
              </div>
              <div class="el-radio">
                <span>116-125</span>
              </div>
              <div class="el-radio">
                <span>126-135</span>
              </div>
              <div class="el-radio">
                <span>136-145</span>
              </div>
              <div class="el-radio">
                <span>146-155</span>
              </div>
              <div class="el-radio">
                <span>156-165</span>
              </div>
              <div class="el-radio">
                <span>166-175</span>
              </div>
              <div class="el-radio">
                <span>176-185</span>
              </div>
              <div class="el-radio">
                <span>186-195</span>
              </div>
              <div class="el-radio border-none">
                <span>186-195</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>治疗收缩压(mmHg)</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>97-105</span>
              </div>
              <div class="el-radio">
                <span>106-112</span>
              </div>
              <div class="el-radio">
                <span>113-117</span>
              </div>
              <div class="el-radio">
                <span>118-123</span>
              </div>
              <div class="el-radio">
                <span>124-129</span>
              </div>
              <div class="el-radio">
                <span>130-135</span>
              </div>
              <div class="el-radio">
                <span>136-142</span>
              </div>
              <div class="el-radio">
                <span>143-150</span>
              </div>
              <div class="el-radio">
                <span>151-161</span>
              </div>
              <div class="el-radio">
                <span>162-176</span>
              </div>
              <div class="el-radio border-none">
                <span>177-205</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>糖尿病</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>吸烟</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>心血管疾病</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>心房纤颤</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td td-width border-right">
            <span>左心房肥厚</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="table">
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>项目</span>
          </div>
          <div class="td flex1 border-right">
            <div class="td-item border-bottom">
              <span>女性评估计分标准</span>
            </div>
            <div class="td-item td-item1">
              <div class="td-item border-right">
                <span>0分</span>
              </div>
              <div class="td-item border-right">
                <span>1分</span>
              </div>
              <div class="td-item border-right">
                <span>2分</span>
              </div>
              <div class="td-item border-right">
                <span>3分</span>
              </div>
              <div class="td-item border-right">
                <span>4分</span>
              </div>
              <div class="td-item border-right">
                <span>5分</span>
              </div>
              <div class="td-item border-right">
                <span>6分</span>
              </div>
              <div class="td-item border-right">
                <span>7分</span>
              </div>
              <div class="td-item border-right">
                <span>8分</span>
              </div>
              <div class="td-item border-right">
                <span>9分</span>
              </div>
              <div class="td-item">
                <span>10分</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>年龄（岁）</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>54-56</span>
              </div>
              <div class="el-radio">
                <span>57-59</span>
              </div>
              <div class="el-radio">
                <span>60-62</span>
              </div>
              <div class="el-radio">
                <span>63-64</span>
              </div>
              <div class="el-radio">
                <span>65-67</span>
              </div>
              <div class="el-radio">
                <span>68-70</span>
              </div>
              <div class="el-radio">
                <span>71-73</span>
              </div>
              <div class="el-radio">
                <span>74-76</span>
              </div>
              <div class="el-radio">
                <span>77-78</span>
              </div>
              <div class="el-radio">
                <span>79-81</span>
              </div>
              <div class="el-radio border-none">
                <span>82-84</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>未治疗收缩压(mmHg)</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span>95-106</span>
              </div>
              <div class="el-radio">
                <span>107-118</span>
              </div>
              <div class="el-radio">
                <span>119-130</span>
              </div>
              <div class="el-radio">
                <span>131-143</span>
              </div>
              <div class="el-radio">
                <span>144-155</span>
              </div>
              <div class="el-radio">
                <span>156-167</span>
              </div>
              <div class="el-radio">
                <span>168-180</span>
              </div>
              <div class="el-radio">
                <span>181-192</span>
              </div>
              <div class="el-radio">
                <span>193-204</span>
              </div>
              <div class="el-radio border-none">
                <span>205-216</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>治疗收缩压(mmHg)</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span>95-106</span>
              </div>
              <div class="el-radio">
                <span>107-113</span>
              </div>
              <div class="el-radio">
                <span>114-119</span>
              </div>
              <div class="el-radio">
                <span>120-125</span>
              </div>
              <div class="el-radio">
                <span>126-131</span>
              </div>
              <div class="el-radio">
                <span>132-139</span>
              </div>
              <div class="el-radio">
                <span>140-148</span>
              </div>
              <div class="el-radio">
                <span>151-161</span>
              </div>
              <div class="el-radio">
                <span>149-160</span>
              </div>
              <div class="el-radio border-none">
                <span>205-216</span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>糖尿病</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>吸烟</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>心血管疾病</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>心房纤颤</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td td-width border-right">
            <span>左心房肥厚</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio">
                <span></span>
              </div>
              <div class="el-radio border-none">
                <span></span>
              </div>
            </div>
          </div>
          <div class="td td-width td-width1 border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准:评估得分越高，10年内率中发病风险越高。男性: 21-3为高度危险，11-2中度危险，1-10分为低度危险，女性: 19-27分为高度危险,10-18 为中度危险，1-9 分为低度危险。</span>
      </div>
      <div class="tip">
        <span>2.风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1)低盐低脂清淡饮食为主，戒烟限酒、控制体重。</span>
      </div>
      <div class="tip">
        <span>(2) 适当锻炼，有氧运动为主。①运动项目: 快走、舞蹈、太极拳、健身等; ② 运动强度指标: 若惠者年龄大于 60岁，心率到达 170年龄，一般不宜超过 110次份。</span>
      </div>
      <div class="tip">
        <span>(3)保持情绪舒畅、稳定，避免过度刺激导致情绪波动过大。注意休息，保持充足的睡眠。</span>
      </div>
      <div class="tip">
        <span>(4)控制血压:</span>
      </div>
      <div class="tip">
        <span>①按血压分: 无高血压或高血压前期患者&lt;120/80mmHg普通高血压患者&lt;140/90mmHg伴糖尿病或蛋白尿肾病的高血压患者&lt;130/80mmHg</span>
      </div>
      <div class="tip">
        <span>②按年龄分: 65-7岁老年人&lt;150/90mmHg如能耐受，则进一步降至&lt;140/90mmHg80岁及其以上老年人&lt;150/90mmHg</span>
      </div>
      <div class="tip">
        <span>(5) 控制血糖:</span>
      </div>
      <div class="tip">
        <span>根据患者健康状况分层的老年糖尿病患者血糖的治疗建议。</span>
      </div>
      <div class="tip">
        <span>①健康(合并较少的慢性疾病、完整的认知和功能状态) : 糖化血红蛋白&lt;7.5% 空腹或餐前血糖 5.0-7.2mmol前血糖 5.0-8.3mmol/L</span>
      </div>
      <div class="tip">
        <span>② 复杂/等程度的健康(多种并存的慢性疾病、或 2项以上的日常活动能力受损、或轻到中度的认知功能障碍): 糖化血红蛋自&lt;8.0% 糖化血红蛋自空腹或餐前血糖 5.0-8.8mmol/L睡前血糖 5.6-10.0mmol/L</span>
      </div>
      <div class="tip">
        <span>③ 非常复杂/健康状况较差(需要长期护理、慢性疾病终未期、或 2项以上的日常活动能力受损、或轻到中度的认知功能障碍) : 糖化血红蛋白&lt;8.5% 空腹或餐前血糖 5.6-10.0mmol/L、睡前血糖 6.1-11.1mmol/L</span>
      </div>
      <div class="tip">
        <span>(6)如有其它疾病，及时进行治疗。① 房颤的患者一定要控制心室率，并且口服抗凝药物。② 高脂血症患者注意合理膳食，适当锻炼，并口服以他汀类为主的降脂药物。</span>
      </div>
      <div class="tip">
        <span>(7)如老年人出现抑郁、焦虑等心理问题，要及时发现，并进行疏导。如果疏导效果欠佳，则需要通过药物进行干预。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.incapacity {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    // .tip:nth-child(2) {
    //   padding-left: .1rem;
    //   box-sizing: border-box;
    // }

    // .tip:nth-child(n + 3) {
    //   padding-left: .2rem;
    //   box-sizing: border-box;
    // }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .td-width1 {
        width: 100px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 60px;
        text-align: center;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-right: 1px solid #000;
  }

  .border-none {
    border-right: none !important;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
