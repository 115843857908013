<template>
  <div class="incapacity">
    <div class="title">
      <span>附表1 压疮风险评估表</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>项目</span>
          </div>
          <div class="td flex1 border-right">
            <div class="td-item border-bottom">
              <span>评估计分标准</span>
            </div>
            <div class="td-item td-item1">
              <div class="td-item border-right">
                <span>1分</span>
              </div>
              <div class="td-item border-right">
                <span>2分</span>
              </div>
              <div class="td-item border-right">
                <span>3分</span>
              </div>
              <div class="td-item">
                <span>4分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>感知能力</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">完全受限</el-radio>
              <el-radio label="2">非常受限</el-radio>
              <el-radio label="3">轻度受限</el-radio>
              <el-radio label="4">无损害</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>潮湿度</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">持久潮湿</el-radio>
              <el-radio label="2">非常潮湿</el-radio>
              <el-radio label="3">偶尔潮湿</el-radio>
              <el-radio label="4">很少潮湿</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>活动能力</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">卧床不起</el-radio>
              <el-radio label="2">局限于椅</el-radio>
              <el-radio label="3">扶助行走</el-radio>
              <el-radio label="4">活动自如</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>移动能力</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">完全受限</el-radio>
              <el-radio label="2">严重受限</el-radio>
              <el-radio label="3">轻度受限</el-radio>
              <el-radio label="4">不受限制</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>营养摄入能力</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">严重不足</el-radio>
              <el-radio label="2">可能不足</el-radio>
              <el-radio label="3">摄入恰当</el-radio>
              <el-radio label="4">摄入良好</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1 border-bottom">
          <div class="td td-width border-right">
            <span>摩擦力和剪切力</span>
          </div>
          <div class="td flex1 border-right">
            <el-radio-group v-model="radio">
              <el-radio label="1">存在风险</el-radio>
              <el-radio label="2">潜在风险</el-radio>
              <el-radio label="3">无风险</el-radio>
              <el-radio disabled class="radio" label="4">————</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td td-width border-right">
            <span>总分</span>
          </div>
          <div class="td flex1 border-right">
            <div class="el-radio-group">
              <span class="el-radio">——</span>
              <span class="el-radio">——</span>
              <span class="el-radio">——</span>
              <span class="el-radio">——</span>
            </div>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准: 15-18分轻度危险; 13-14分中度危险; 10-12分高度危险; ≤9分极高危险</span>
      </div>
      <div class="tip">
        <span>2.压疮风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1)对长期卧床、活动受限或感知觉障碍的老年人应每2小时变换体位1次,压风险程度评估为极高危险时应增加翻身频次，可使用气垫床或在骨隆突处采取局 部减压及预防压疮措施</span>
      </div>
      <div class="tip">
        <span>(2) 应保持老年人皮肤清洁干燥，对出汗、大小便失禁的老年人应及时更换湖 湿被服。</span>
      </div>
      <div class="tip">
        <span>(3) 搬运卧床老年人时，应采用双人及以上人员搬运法，或采用提单式、过床 易等搬运法，避免拖、拉、拽、扯等动作</span>
      </div>
      <div class="tip">
        <span>(4)密切观察老年人受压处皮肤情况，不应按摩局部已压红皮肤，宜使用预防 压疮敷料或按摩周围皮肤姐织。</span>
      </div>
      <div class="tip">
        <span>(5) 改善老年人全身营养状况，每月测量体重不应少于1次，可计算体重指数。</span>
      </div>
      <div class="tip">
        <span>(6) 应保持床单平整、清洁、干燥、无碎屑。</span>
      </div>
      <div class="tip">
        <span>(7)使用鼻导管、面罩、夹板、石膏等医源性干预治疗的老年人，应对局部皮 肤观察与防护。</span>
      </div>
      <div class="tip">
        <span>(8) 卧床老年人使用便器时，应抬起老年人的臀部，防止拖拽。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.incapacity {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(3), .tip:nth-child(4) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 5) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 40px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-right: 1px solid #000;
  }

  .el-radio:nth-child(4) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
