<template>
  <div class="fall">
    <div class="title">
      <span>附表8 管路滑脱风险评估</span>
    </div>
    <div class="scroll">
      <div class="table">
        <div class="tr tr-height1 border-bottom">
          <div class="td flex1 border-right center center1">
            <span>项目</span>
          </div>
          <div class="td td-width border-right">
            <span>分值</span>
          </div>
          <div class="td td-width border-right center center1">
            <span>得分</span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>年龄</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">&lt;65岁</el-radio>
              <el-radio label="1">≧65岁</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>2分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height2 border-bottom">
          <div class="td td-width border-right">
            <span>意识状态</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">昏迷</el-radio>
              <el-radio class="border-bottom" label="1">嗜睡</el-radio>
              <el-radio label="2">谵妄</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio">
                <span>3分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height2 border-bottom">
          <div class="td td-width border-right">
            <span>精神状态</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">焦虑</el-radio>
              <el-radio class="border-bottom" label="1">恐惧</el-radio>
              <el-radio label="2">烦躁</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>2分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio">
                <span>3分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height3 border-bottom">
          <div class="td td-width border-right">
            <span>活动情况</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">术后3天内</el-radio>
              <el-radio class="border-bottom" label="1">行动不稳</el-radio>
              <el-radio class="border-bottom" label="2">偏瘫</el-radio>
              <el-radio class="border-bottom" label="3">使用助行器具</el-radio>
              <el-radio label="4">不能自主运动</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>3分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height2 border-bottom">
          <div class="td td-width border-right">
            <span>管路种类</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">一类（高危）</el-radio>
              <el-radio class="border-bottom" label="1">二类（中危）</el-radio>
              <el-radio label="2">三类（低危）</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>3分</span>
              </div>
              <div class="el-radio border-bottom">
                <span>2分</span>
              </div>
              <div class="el-radio">
                <span>1分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>疼痛程度</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">可耐受</el-radio>
              <el-radio label="1">难以耐受</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>3分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height border-bottom">
          <div class="td td-width border-right">
            <span>沟通能力</span>
          </div>
          <div class="td flex1 border-right center center1">
            <el-radio-group v-model="radio">
              <el-radio label="0">一般、能理解</el-radio>
              <el-radio label="1">差、不配合</el-radio>
            </el-radio-group>
          </div>
          <div class="td td-width border-right">
            <div class="el-radio-group">
              <div class="el-radio">
                <span>1分</span>
              </div>
              <div class="el-radio">
                <span>3分</span>
              </div>
            </div>
          </div>
          <div class="td td-width border-right center center1">
            <span></span>
          </div>
        </div>
        <div class="tr tr-height1">
          <div class="td border-right flex1 center center1">
            <span>总分</span>
          </div>
          <div class="td td-width border-right">
            <span>40分</span>
          </div>
          <div class="td td-width border-right">
            <span></span>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>说明：</span>
      </div>
      <div class="tip">
        <span>1.评估标准 : 评分> 12 分为高度危险，随时会发生管路滑脱 ; 评分 8-12分为中度危险，容易发生管路滑脱;评分&lt; 8 分为任度危险，有发生管路滑脱的可能(多条管路按危险度累加，如2 条中危管路为 4 分)。</span>
      </div>
      <div class="tip">
        <span>2.管路种类: 一类 (高危)-气管切开套管; 二类(中危) -CVC 、PICC 、各类造管、腹腔引流管;三类《低危) - 导尿管、胃管。</span>
      </div>
      <div class="tip">
        <span>3.管路滑脱风险指导内容:</span>
      </div>
      <div class="tip">
        <span>(1) 评分&lt; 8 分: 妥善固定导管，留有一定长度，每日测量导管长度;保持导管 通畅，密切关注导管情况。</span>
      </div>
      <div class="tip">
        <span>(2) 评分≥ 28 分: 在上述护理措施的基础上，强化患者及家属的宣教，直至掌握。</span>
      </div>
    </div>
    <div class="button">
      <el-button size="small" type="primary">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.fall {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .tip:nth-child(3), .tip:nth-child(4), .tip:nth-child(5) {
      padding-left: 8px;
      box-sizing: border-box;
    }

    .tip:nth-child(n + 6) {
      padding-left: 16px;
      box-sizing: border-box;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;
      }

      .td {
        height: 100%;
        color: #000;
        font-size: 18px;
      }

      .td-width {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
      }

      .tr-height {
        height: 72px;
      }

      .tr-height1 {
        height: 40px;
      }

      .tr-height2 {
        height: 108px;
      }

      .tr-height3 {
        height: 180px;
      }

      .flex1 {
        flex: 1;
      }

      .td-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 36px;
        text-align: center;
      }

      .td-item1 {
        display: flex;
        align-items: center;
      }

      .center {
        display: flex;
        align-items: center;
      }

      .center1 {
        justify-content: center;
      }
    }
  }

  .el-radio-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .el-radio {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-right: 0 !important;
    color: #000;
    border-bottom: 1px solid #000;
  }

  .el-radio:nth-child(n + 2) {
    border: none;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000 !important;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
