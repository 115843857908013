<template>
  <div class='reviewRun'>
    <div class="left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="right">
      <div class="tab-item">
        <AddAssess></AddAssess>
      </div>
    </div>
    <BasicInfoPopup v-if="showBasicInfoPopup" @close="closeBasicInfoPopup"></BasicInfoPopup>
  </div>
</template>
<script>
import AddAssess from './addAssess.vue'
import BasicInfoPopup from '../monitoring/basicInfoPopup.vue'
import UserBasicInfo from '../../../components/user-basic-info/user-basic-info.vue'
export default {
  components: {
    AddAssess,
    BasicInfoPopup,
    UserBasicInfo
  },
  data () {
    return {
      moveTop: null,
      showBasicInfoPopup: false
    }
  },
  methods: {
    openBasicInfoPopup () {
      this.showBasicInfoPopup = true
    },
    closeBasicInfoPopup () {
      this.showBasicInfoPopup = false
    }
  },
  mounted () {
    // const move = document.querySelector('.movetop')
    // this.moveTop = move.offsetHeight
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.reviewRun {
  position: relative;
  width: calc(100% - .125rem);
  height: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #fff;

  .left {
    width: 15%;
    min-width: 190px;
    height: 100%;

    .title {
      width: 100%;
      height: .4375rem;
      line-height: .4375rem;
      padding-left: .425rem;
      padding-top: .0625rem;
      box-sizing: border-box;
      background: url('../img/dialogTitleBg.png') no-repeat;
      background-size: 100% 100%;
      color: #fff;
      font-size: .25rem;
      font-weight: 600;
    }

    .scroll {
      overflow: scroll;
      width: 100%;
      height: 9.4rem;
      margin-top: .0625rem;
      border-radius: .075rem;
      background-color: rgba(29, 64, 112, .33);

      .list {
        display: flex;
        align-items: center;
        width: 7.625rem;
        height: .625rem;
        color: #01EEFD;
        font-size: .2rem;
        cursor: pointer;

        div {
          width: .75rem;
          text-align: center;
        }

        .flex2 {
          width: 1.25rem;
        }

        .flex3 {
          width: 1.875rem;
        }

        .flex4 {
          width: 3.75rem;
        }
      }

      &::-webkit-scrollbar {
        display: none;
      }

      .color {
        position: sticky;
        top: 0;
        left: 0;
        padding: .05rem 0;
        z-index: 88;
        background-color: rgba(14, 40, 75, 1);
      }

      .movetop {
        // animation: moveani var(--time) infinite linear normal;
      }

      @keyframes moveani {
        0% {
          transform: translateY(0);
        }

        100% {
          transform: translateY(var(--moveTop));
        }
      }

      .movetop:hover {
        animation-play-state: paused;
      }
    }
  }

  .right {
    overflow: hidden;
    width: 84%;
    height: 100%;

    .tab-item {
      width: 100%;
      height: 99%;
      padding: 5px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        display: none;
      }
    }

  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
