<template>
  <div class="life">
    <div class="title">
      <span>老年人能力评估标准（试行）</span>
    </div>
    <div class="scroll">
      <div class="tip">
        <span>根据对老年人日常生活活动能力、精神状态与社会参与能力、感知觉与沟通储力3
          个维度评估的评分情况，将老年人能力评定 为 4 个等级，即完好、轻度受损、中度受损重度受损。 先根据
          日常生活活动能力得分情况确定区间，再分别结合精神状态与社会参与能力以及感知觉与沟通能力得分情况确定老年人能力等级，以最严重的老年人能力等级为准 。老年综合征罹患情况是作
          为失能单独晋级,老年罹患综合征评5-9分能力等级提升 1 级 ; 10-19 分能力等级提升 2 级,20 分及以上直接判断重度失能。</span>
      </div>
      <div class="title">
        <span>老年人能力评定总表</span>
      </div>
      <div class="table">
        <div class="tr tr1 border-bottom">
          <div class="td td1 border-right">
            <span>日常生活活动能力</span>
          </div>
          <div class="td flex1 border-right">
            <div class="td-item border-bottom">
              <span>精神状态与社会参与能力</span>
            </div>
            <div class="td-item">
              <div class="td-item-flex border-right">
                <span>0 分</span>
              </div>
              <div class="td-item-flex border-right">
                <span>1-8 分</span>
              </div>
              <div class="td-item-flex border-right">
                <span>9-24 分</span>
              </div>
              <div class="td-item-flex">
                <span>25-40 分</span>
              </div>
            </div>
          </div>
          <div class="td flex1">
            <div class="td-item border-bottom">
              <span>感知觉与沟通能力</span>
            </div>
            <div class="td-item">
              <div class="td-item-flex border-right">
                <span>0 分</span>
              </div>
              <div class="td-item-flex border-right">
                <span>1-8 分</span>
              </div>
              <div class="td-item-flex border-right">
                <span>9-24 分</span>
              </div>
              <div class="td-item-flex">
                <span>25-40 分</span>
              </div>
            </div>
          </div>
        </div>
        <div class="tr tr2 border-bottom">
          <div class="td td1 border-right">
            <span>0 分</span>
          </div>
          <div class="td td2 border-right">
            <span>完好</span>
          </div>
          <div class="td td2 border-right">
            <span>完好</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>完好</span>
          </div>
          <div class="td td2 border-right">
            <span>完好</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2">
            <span>轻度受损</span>
          </div>
        </div>
        <div class="tr tr2 border-bottom">
          <div class="td td1 border-right">
            <span>1-20 分</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>轻度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2">
            <span>中度受损</span>
          </div>
        </div>
        <div class="tr tr2 border-bottom">
          <div class="td td1 border-right">
            <span>21-40 分</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>中度受损</span>
          </div>
          <div class="td td2">
            <span>重度受损</span>
          </div>
        </div>
        <div class="tr tr2 border-bottom">
          <div class="td td1 border-right">
            <span>41-60 分</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2 border-right">
            <span>重度受损</span>
          </div>
          <div class="td td2">
            <span>重度受损</span>
          </div>
        </div>
        <div class="tr tr1 border-bottom">
          <div class="td td1 border-right">
            <span>评分得分</span>
          </div>
          <div class="td td2 td3 flex">
            <div class="item">
              <span>日常生活活动能力</span>
              <el-input></el-input>
              <span>分；</span>
            </div>
            <div class="item">
              <span>精神状态与社会参与能力</span>
              <el-input></el-input>
              <span>分；</span>
            </div>
            <div class="item">
              <span>感知觉与沟通能力</span>
              <el-input></el-input>
              <span>分；</span>
            </div>
            <div class="item">
              <span>老年综合征罹患情况</span>
              <el-input></el-input>
              <span>分；</span>
            </div>
          </div>
        </div>
        <div class="tr tr2">
          <div class="td td1 border-right">
            <span>评估结果</span>
          </div>
          <div class="td flex1 flex">
            <el-checkbox-group v-model="checkList">
              <el-checkbox label="0">能力完好</el-checkbox>
              <el-checkbox label="1">轻度失能</el-checkbox>
              <el-checkbox label="2">中度失能</el-checkbox>
              <el-checkbox label="3">重度失能</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="tip">
        <span>备注：老年人日常生活活动能力、精神状态与社会参与能力、感知觉与沟通能力、老年综合征罹患情况评分分别见附 表 1、2、3、4。</span>
      </div>
    </div>
    <div class="button">
      <el-button type="primary" size="small">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      checkList: ['0']
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.life {
  width: 99%;
  height: 100%;
  margin: 0 auto;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 100px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .tip {
      width: 95%;
      margin: 10px auto;
      color: #000;
      font-size: 18px;
    }

    .table {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 10px;
      border: 1px solid #000;
      box-sizing: border-box;

      .tr {
        display: flex;
        align-items: center;

        .td {
          color: #000;
          font-size: 18px;
        }

        .td1 {
          display: flex;
          align-items: center;
          width: 104px;
          height: 100%;
          padding: 0 8px;
          box-sizing: border-box;
        }

        .td2 {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 12.5%;
          height: 100%;
        }

        .td3 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .flex {
          display: flex;
          align-items: center;
          padding-left: 10px;
          box-sizing: border-box;
        }

        .flex1 {
          flex: 1;
          height: 100%;
        }

        .td-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 50%;

          .td-item-flex {
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            height: 100%;
          }
        }

        .item {
          width: 50%;

          .el-input {
            width: 100px;
          }

          /deep/.el-input__inner {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #000;
            box-sizing: border-box;
            color: #000;
            border-radius: 0;
          }
        }
      }

      .tr1 {
        height: 1.1rem;
      }

      .tr2 {
        height: .625rem;
      }
    }
  }

  .el-checkbox {
    color: #000;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
</style>
