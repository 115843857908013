<template>
  <div class="life">
    <div class="title">
      <span>附表 1 老年人日常生活活动能力评分表</span>
    </div>
    <div class="thead">
      <div class="td td1 border-right">
        <span>评估项目</span>
      </div>
      <div class="td flex1 border-right">
        <span>具体评价指标</span>
      </div>
      <div class="td td2 border-right">
        <span>分值</span>
      </div>
      <div class="td td2">
        <span>评分</span>
      </div>
    </div>
    <div class="scroll">
      <div class="tr">
        <div class="td td1 border-right">
          <span>1.卧位状态左右翻</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios1" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>2.床椅转移</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios2" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>3.平地步行</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios3" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>4.非步行移动</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios4" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>5.活动耐力</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios5" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>6.上下楼梯</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios6" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>7.食物摄取</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios7" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>8.修饰包括刷牙漱口、洗脸、洗手梳头</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios8" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>9.穿/脱上衣</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios9" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>10.穿/脱裤子</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios9" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>11.身体清洁</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios9" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>12.使用厕所</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios9" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>13.使用厕所</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios10" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>14.大便控制</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios10" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr">
        <div class="td td1 border-right">
          <span>15.服用药品</span>
        </div>
        <div class="td flex1 border-right">
          <el-radio-group v-model="radio">
            <el-radio v-for="(item, index) in radios11" :key="index" :class="{ 'border-bottom': index !== 4 }" :label="index">{{ item }}</el-radio>
          </el-radio-group>
        </div>
        <div class="td td2 border-right">
          <div v-for="(item, index) in fens" :key="index" class="item" :class="{ 'border-bottom': index !== 4 }">
            <span>{{ item }}分</span>
          </div>
        </div>
        <div class="td td2"></div>
      </div>
      <div class="tr tr1">
        <div class="td td1 border-right"></div>
        <div class="td flex1">
          <span>上述项目总分为60分，本次评估得分为</span>
          <el-input></el-input>
          <span>分</span>
        </div>
      </div>
    </div>
    <div class="button">
      <el-button type="primary" size="small">确定</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '0',
      radios1: Object.freeze(['不需要帮助', '在他人的语言指导下或照看下能够完成', '需要他人动手帮助，但以自身完成为主', '主要靠帮助，自身只是配合', '完全需要帮助，或更严重的情况']),
      radios2: Object.freeze(['个体可以独立地完成床椅转移', '个体在床椅转移时需要他人监控或指导', '个体在床椅转移时需要他人小量接触式帮助', '个体在床椅转移时需要他人大量接触式帮助', '个体在床椅转移时完全依赖他人']),
      radios3: Object.freeze(['个体能独立平地步行 50m 左右，且无摔倒风险', ' 个体能独立平地步行50m左右，但存在摔倒风险，需要他人监控，或使用拐杖、助行器等辅助工具', '个体在步行时需要他人小量扶持帮助', '个体在步行时需要他人大量扶持帮助', '无法步行，完全依赖他人']),
      radios4: Object.freeze(['个体能够独立地使用轮椅(或电动车)从 A 地移动到B地', '个体使用轮椅 (或电动车)从 A 地移动到B 地时需要监 护或指导', '个 体使用轮椅(或电动车)从A 地移动到日地时需要小量接触式帮助', '个体使用轮椅 (或电动车) 从 A 地移动到 B 地时需要大量接触式帮助', '个体使用轮椅(或电动车) 时完全依赖他人']),
      radios5: Object.freeze(['正常完成日常活动，无疲劳', '正常完成日常活动轻度费力，有疲劳感', '完成日常活动比较费力，经常疲劳', '完成日常活动十分费力，绝大多数时候都很疲劳', '不能完成日常活动，极易疲劳']),
      radios6: Object.freeze(['不需要帮助', '在他人的语言指导下或照看下能够完成', '需要他人动手帮助，但以自身完成为主', '主要靠帮助，自身只是配合', '完全需要帮助，或更严重的情况']),
      radios7: Object.freeze(['不需要帮助', '在他人的语言指导下或照看下能够完成', '使用餐具有些困难，但以自身完成为主', '需要喂食，喂食量超过一半', '完全需要帮助，或更严重的情况']),
      radios8: Object.freeze(['不需要帮助', '在他人的语言指导下或照看下能够完成', '需要他人动手帮助，但以自身完成为主', '主要靠帮助，自身只是配合', '完全需要帮助，或更严重的情况']),
      radios9: Object.freeze(['不需要帮助', '在他人的语言指导下或照看下能够完成', '需要他人动手帮助，但以自身完成为主', '主要靠帮助，自身只是配合', '完全需要帮助，或更严重的情况']),
      radios10: Object.freeze(['每次都能不失控', '每月失控1-3次左右', '每周失控1次左右', '每天失控1次左右', '每次都失控']),
      radios11: Object.freeze(['能自己负责在正确的时间服用正确的药物', '在他人的语言指导下或照看下能够完成', '如果事先准备好服用的药物份量，可自行服药', '主要依靠帮助服药', '完全不能自行服用药物']),
      fens: Object.freeze(['0', '1', '2', '3', '4'])
    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.life {
  width: 99%;
  height: 100%;
  margin: 0 auto;
  // border: 1px red solid;

  .title {
    text-align: center;
    color: #000;
    font-size: 22px;
  }

  .thead {
    display: flex;
    width: 100%;
    height: 40px;
    margin-top: 10px;
    border: 1px solid #000;
    box-sizing: border-box;

    .td {
      height: 40px;
      line-height: 40px;
      color: #000;
      font-size: 18px;
      text-align: center;
    }

    .td1 {
      width: 115px;
    }

    .td2 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }
  }

  .scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 200px);
    border-bottom: 1px solid #000;
    box-sizing: border-box;

    &::-webkit-scrollbar {
      width: 0;
    }

    .tr {
      display: flex;
      height: 200px;
      border: 1px solid #000;
      border-bottom: none;
      box-sizing: border-box;
    }

    .tr1 {
      height: 64px;

      .el-input {
        width: 80px;
        background-color: transparent;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #000;
      }
    }

    .td {
      height: 100%;
      color: #000;
      font-size: 18px;
    }

    .td1 {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 2px;
      box-sizing: border-box;
    }

    .td2 {
      width: 100px;
    }

    .flex1 {
      flex: 1;
    }

    .el-radio-group {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .el-radio {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        padding-left: 10px;
        box-sizing: border-box;
        color: #000;
      }

      /deep/.el-radio__label {
        white-space: pre-wrap;
      }
    }

    .item {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color: #000;
      font-size: 18px;
    }
  }

  .border-right {
    border-right: 1px solid #000;
    box-sizing: border-box;
  }

  .border-bottom {
    border-bottom: 1px solid #000;
    box-sizing: border-box;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
}
</style>
